.icon {
  display: inline-block;
  line-height: 1;
}

.fill {
  fill: currentColor;
}

.stroke {
  stroke: currentColor;
}
