@import '../../styles/variables';

.wrapper {
  height: 94px;
  width: 100%;
  background: $white;
  z-index: 5;

  @media (max-width: 1366px) {
    
  }
  @media (max-width: 1080px) {
    height: 86px;
  }
  @media (max-width: 375px) {
    height: 40px;
  }
}

.container {
  width: 100%;
  height: 78px;
  max-width: 1440px;
  margin: 1rem auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.125rem 0.75rem 2rem;
  border-radius: 1.25rem;
  background: $blue-8;

  @media (max-width: 1366px) {
    margin: 1rem 60px 0;
    max-width: 1246px;
    width: calc(100% - 120px);
  }
  @media (max-width: 1080px) {
    height: 70px;
    margin: 1rem 2rem 0;
    max-width: 1016px;
    width: calc(100% - 4rem);
    gap: 1rem;
  }
  @media (max-width: 375px) {
    height: 40px;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
}

.logo {
  cursor: pointer;
  display: none;
  > svg {
    @media (max-width: 1080px) {
      scale: 0.74;
    }
    @media (max-width: 375px) {
      scale: 0.6;
    }
  }
}

.menu {
  list-style: none;
  display: flex;
  flex-grow: 1;
  padding: 0;
  justify-content: center;
  gap: 36px;

  @media (max-width: 1080px) {
    gap: 1rem;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.menuItem {
  display: flex;

  margin: 0;
}

.menuLink,
.phone > a > span {
  font-family: $font-primary;
  font-family: var(--font-primary);
  font-style: normal;
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 1080px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.menuLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.menuLink, .phone>a {
  color: $gray-15;
  color: var(--gray-15);
  &:visited {
    color: $gray-15;
    color: var(--gray-15);
  }
  &:active,
  &:hover,
  &:visited:hover,
  &.active {
    color: $blue-2-1;
    color: var(--blue-2-1);
  }
}

.phone {
  width: 160px;

  @media (max-width: 900px) {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.login {
  display: flex;
  align-items: center;

  @media (max-width: 1080px) {}

  @media (max-width: 540px) {
    display: none;
  }
}

.lines {
  display: none;

  @media (max-width: 540px) {
    display: block;

    &:active,
    &:hover {
      > svg > path {
        fill: $white;
        fill: var(--white);
      }
    }
  }
}
