@import '../../../styles/variables';

.container {
  max-width: 1440px;
  width: 100%;
  align-self: center;
  margin: 64px 0 24px 0;
  padding: 0 1.5rem;
  z-index: 10;
  display: flex;
  gap: 0.25rem;

  @media (max-width: 1199px) {
    margin: 80px auto 24px 0;
    max-width: initial;
  }

  @media (max-width: 480px) {
    margin-left: 0;
  }

  @media (max-width: 360px) {
    display: none;
    padding: 0 1rem;
  }

  @media (max-width: 288px) {
    padding: 0 0.75rem;
  }

  a, a:visited {
    color: $gray-1;
  }
}

.container.withPadding {
  padding: 0 $content-padding;
  padding: 0 var(--content-padding);
}

.link,
.text {
  font-family: $font-primary;
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.025em;
  display: inline-block;
}

.link {
  padding-right: 5px;
  text-decoration: none;
  color: $blue-2;
  color: var(--blue-2);
}

.text {
  color: $gray-3;
  color: var(--gray-3);
}
