@import '../../../styles/variables';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.leftIcon > path,
.rightIcon > path {
  stroke: $gray_blue;
  stroke: var(--gray_blue);
}

.leftIcon {
  transform: rotate(180deg);
}

.pagination > li {
  margin: 3px 12px;
  width: 9px;
  height: 28px;
  list-style-type: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination > li > a,
.paginationBreak {
  font-family: $font-primary;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: $gray_blue;
  color: var(--gray_blue);
}

.pagination > li.paginationActive {
  margin: 3px;
  width: 28px;
  border-radius: 8px;
  background-color: $blue-2;
  background-color: var(--blue-2);
}

.pagination > li.paginationActive > a {
  color: $white;
  color: var(--white);
}
