.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
}
.col {
  display: flex;
  flex-direction: column;
  width: 100%;
}
