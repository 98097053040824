.ReactModal {
  &__Overlay {
    opacity: 0;
    transition: opacity 50ms ease-in-out;
    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }

    display: block;
    position: fixed;
    inset: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5) !important;

    overflow-x: hidden;
    overflow-y: auto;

    z-index: 10;
  }

  &__Content {
    background-color: white;
    margin: 40px auto;
    outline: none;
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  //overflow: hidden;
}

.ReactModal__Body--open {
  overflow: hidden;
  padding-right: 17px;
}
