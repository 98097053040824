@import '../styles/variables';

.field {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.textarea {
  font-size: 14px;
  color: $gray-1;
  color: var(--gray-1);
  padding: 0.75rem 1rem 0 1rem;
  height: 100%;
  width: 100%;
  background: $white;
  background: var(--white);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  outline: none;
  resize: none;

  &:focus {
    border: 1px solid $blue-2;
    border: 1px solid var(--blue-2);
    outline: 1px solid $blue-2;
    outline: 1px solid var(--blue-2);
  }

  &.error:not(:focus) {
    border: 1px solid $red;
    border: 1px solid var(--red);
    outline: 1px solid $red;
    outline: 1px solid var(--red);
  }

  &:disabled {
    background-color: $gray-7;
    background-color: var(--gray-7);
    color: $gray-3;
    color: var(--gray-3);
  }

  &::placeholder {
    font-size: 14px;
    color: $gray_blue;
    color: var(--gray_blue);
  }
}

.errorMessage {
  margin-left: 16px;
}
