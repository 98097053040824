@import '../../../styles/variables';

.form {
  width: 100%;
  margin-bottom: 3rem;

  > .field {
    margin-bottom: 12px;
  }

  > .message {
    height: 180px;

    @media (max-width: 367px) {
      height: 135px;
    }
  }
}

.footer {
  position: absolute;
  right: 2rem;
  bottom: 2rem;

  .sendBtn:hover {
    color: $white;
    color: var(--white);
    background: $red;
    background: var(--red);
    transition: 0.5s;
  }
}
