@import '../styles/variables';

.button {
  display: flex;
  justify-content: center;
  font-weight: 500;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 24px;
  border: 1px solid transparent;
  font-family: $font-primary;
  align-items: center;
  gap: 1rem;
}

.primary {
  // background: linear-gradient(360deg, #2193b0 4.08%, #6dd5ed 100%);
  background: $red;
  color: #ffffff;
  border: none;
  &:hover {
    background: $red-2;
  }
  &:focus {
    border: 1px solid $gray-1;
  }
  &:disabled {
    opacity: 60%;
  }
}

.secondary {
  background: $white;
  background: var(--white);
  color: $red;
  color: var(--red);
  border: 1px solid $red;
  border: 1px solid var(--red);

  &:hover {
    background: $red;
    color: #ffffff;
  }
  &:focus {
    color: $gray-1;
    border: 1px solid $gray-1;
  }
  &:disabled {
    color: $gray-1;
    border: 1px solid $red;
    opacity: 60%;
  }
}

.stroke {
  background: $white;
  background: var(--white);
  color: $gray-1;
  color: var(--gray-1);
  border: 1px solid rgba($gray-1, 0.16);

  &:hover, &:hover:focus {
    color: $red;
    border: 1px solid $red;
  }
  &:active:hover {
    color: $red-3;
    border: 1px solid $red-3;
  }
  &:disabled {
    color: rgba($gray-1, 0.24);
    border: 1px solid rgba($gray-1, 0.24);
  }
}

.green {
  background: linear-gradient(180deg, #aad485 0%, #aad485 2%, #6cbe45 100%);
  color: #ffffff;
  border: none;
}

.blue {
  background: $blue-2-2;
  background: var(--blue-2-2);
  color: #ffffff;
  border: none;

  &:hover, &:hover:focus {
    background: $blue-2-3;
    // border: 1px solid $blue-2-3;
  }
  &:active:hover {
    background: $blue-2-4;
    // border: 1px solid $red-3;
  }
}

.transparent {
  background: none;
  background: none;
  color: $gray-1;
  color: var(--gray-1);
  border: 1px solid $gray-1;
  border: 1px solid var(--gray-1);
}

.medium {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.large {
  height: 46px;
  line-height: 46px;
  font-size: 18px;
}
