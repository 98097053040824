@font-face {
  font-family: 'Inter';
  src:
    url('/assets/fonts/Inter-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    url('/assets/fonts/Inter-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    url('/assets/fonts/Inter-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    url('/assets/fonts/Inter_18pt-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}
