@import '../../styles/variables';

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  position: relative;
  z-index: 3;
  font-family: $font-primary;

  @media (max-width: 1366px) {
    width: calc(100% - 120px);
    max-width: 100%;
  }

  @media (max-width: 1080px) {
    width: calc(100% - 4rem);
  }

  a {
    color: $blue-7;
    color: var(--blue-7);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;

    &:visited {
      color: $blue-7;
      color: var(--blue-7);
    }

    &:active,
    &:hover,
    &:visited:hover,
    &.active {
      color: $blue-2-1;
      color: var(--blue-2-1);
    }
  }

  ul {
    margin: 0;
    padding-inline-start: 0;
    list-style: none;
  }

  >div {
    align-items: center;
    width: 100%;
    padding: 2.75rem 2.5rem;
    justify-content: space-between;
    background: $gray-8;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  
    @media (max-width: 1366px) {
      padding: 40px;
    }
  
    @media (max-width: 925px) {
      align-items: flex-start;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      gap: 2rem;
    }
  
    .col {
      width: 11.5rem;
  
      p {
        margin: 0;
      }
    }
  
    .rowMenu {
      gap: 2.5rem;
      display: flex;
      align-items: center;
  
      @media (max-width: 1080px) {
        gap: 1rem;
      }
      @media (max-width: 630px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  
    > svg {
      display: none;
      @media (max-width: 1080px) {
        scale: 0.74;
      }
    }
  }
}

.phone, .email {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
}