@import '../styles/variables.scss';

.wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1199px) {
    overflow-x: hidden;
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
